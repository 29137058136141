import { Config } from '@danone-global/internal/react/core'

import { baseEnvironment } from './environment.base'

export const environment: Config = {
  ...baseEnvironment,

  isProduction: true,

  isDevelopment: false,

  ctProjectKey: 'nl-prd-danone',

  ctClientId: 'HTrR94xSToxOIEu5GosdsH-5',

  ctClientSecret: 'bFRZrOgi8Ae_bzFi2q4qSbYta_PyahbI',

  defaultChannelId: 'a04076c1-e70a-4855-bbfa-d9c9ea5c9760',

  sentryDSN: 'https://26e95ebef18de217c29264c5f256280b@o4506427613577216.ingest.sentry.io/4506433323008000',

  loqate: {
    apiKey: 'WN92-JT12-EC33-FH28',
    countries: ['NL'],
  },

}
