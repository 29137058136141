import React from 'react'
import type { Widget } from '@danone-global/internal/react/core'

export default {

  tags: ['signup-v2'],

  Component: React.lazy(() => import('./signup.widget'))

} as Widget
