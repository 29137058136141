import { messages } from '@danone-global/internal/react/modules/checkout'
import React from 'react'

export const baseEnvironment = {
  storeName: 'NUTRICIA',
  siteName: 'nl-nutricia',
  siteSlug: 'nl-nutricia',
  defaultLocale: 'nl-NL',
  localeConfigs: [
    {
      country: 'NL',
      currency: 'EUR',
      initMessages: () =>
        import('../translations/nl_NL.json').then((r) => r.default),
      locale: 'nl-NL',
      urls: {
        privacy: '/hcp/privacy-policy.html',
      },
    },
  ],
  providerId: 'danis_eu',

  ctHost: 'https://api.europe-west1.gcp.commercetools.com',
  ctAuthHost: 'https://auth.europe-west1.gcp.commercetools.com',

  ctStoreKey: 'NUTRICIA',

  analytics: {
    tracker: 'aem',
    language: 'nl-NL',
    defaultCategory: 'Baby food',
  },
  checkout: {
    emailOptional: true,
    stepAddress: React.lazy(
      () =>
        // prettier-ignore
        import('@danone-global/internal/react/components/checkout/nl-step-address'),
    ),

    payment: React.lazy(
      () =>
        // prettier-ignore
        import('@danone-global/internal/react/components/payment-providers/adyen-drop-in'),
    ),

    checkboxes: [
      {
        name: 'agreeTerms',
        required: true,
        store: false,
        label: messages.agreeTerms,
      },
    ],
    
  },
  signupStaticFields: {
    website: 'nutricia.nl',
    domain: 'NLNUTRICIA',
    clubId: 'NL NUTRICIA',
    brand: 'Nutricia',
    countryDivision: 'NL',
    division: 'SN',
    region: 'EMEA',
    typeOfMember: 'HCP',
    regSource: 'Website',
    cMarketingCode: 'standard',
  },
}
